import React, { useCallback, useEffect, useState } from "react";
import styles from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Button, Breadcrumb } from "@themesberg/react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactOwner } from "../../yup";
import { getContactOwner, updateContactOwner } from "../../service/api";
import { showNotification } from "../../service/showNotification";
import ContactOwnerTable from "./ContactOwnerTable";

export const ContactOwner = () => {
  const {
    register,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactOwner),
  });
  const userId = watch("userId");

  const [disableInput, setDisableInput] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetch, setIsRefetch] = useState(false)

  const submitOwner = async (values) => {
    setIsLoading(true);
    try {
      const result = await updateContactOwner(values);
      console.log(result, "result")
      if (!result.success) throw new Error(result.message);
      reset();
      setDisableInput(true);
      showNotification("success","Contact Owner details saved successfully")
      setIsRefetch(!isRefetch)
    } catch (err) {
      console.log("Error", err);
      showNotification("success","Something went wrong")
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOwnerData = useCallback(async (id) => {
    try {
      const result = await getContactOwner(id);
      if (!result.success) throw new Error(result.message);
      const { data } = result;
      setValue("ownerName", data.ownerName || "");
      setValue("ownerRole", data.ownerRole || "");
      setValue("ownerPhone", data.ownerPhone || "");
      setValue("ownerEmail", data.ownerEmail || "");
      setValue("subOwnerName", data.subOwnerName || "");
      setValue("subOwnerRole", data.subOwnerRole || "");
      setValue("subOwnerEmail", data.subOwnerEmail || "");
      setValue("subOwnerPhone", data.subOwnerPhone || "");
      setDisableInput(false);
    } catch (err) {
      showNotification("error", err?.response?.data?.message || "User Id not found");
      console.log("Error", err);
    }
  }, [setValue, submitOwner])

  useEffect(() => {
    if (userId && userId.length === 9) {
      fetchOwnerData(userId);
    }
  }, [userId, fetchOwnerData]);

  return (
    <>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mx-3">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb
            className="d-none d-md-inline-block "
            listProps={{
              className: "breadcrumb-dark breadcrumb-transparent ",
            }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Contact Owner</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Contact Owner</h4>
        </div>
      </div>
      <div>
        <form className={styles.ownerForm} onSubmit={handleSubmit(submitOwner)}>
          <div>
            <label htmlFor="userId" className={styles.ownerLabel}>
              User Id
            </label>
            <input
              type="number"
              className={styles.ownerInput}
              id="userId"
              {...register("userId")}
            />
            <p className={styles.formError}>
              {errors?.userId?.message}
            </p>
          </div>
          <div>
            <label htmlFor="ownerName" className={styles.ownerLabel}>
              Contact Owner Name
            </label>
            <input
              className={styles.ownerInput}
              id="ownerName"
              {...register("ownerName")}
              disabled={disableInput}
            />
          </div>
          <div>
            <label htmlFor="ownerEmail" className={styles.ownerLabel}>
              Contact Owner Email
            </label>
            <input
              className={styles.ownerInput}
              id="ownerEmail"
              {...register("ownerEmail")}
              disabled={disableInput}
            />
          </div>
          <div>
            <label htmlFor="ownerPhone" className={styles.ownerLabel}>
              Contact Owner Phone
            </label>
            <input
              type="text"
              className={styles.ownerInput}
              id="ownerPhone"
              {...register("ownerPhone")}
              disabled={disableInput}
            />
          </div>
          <div>
            <label htmlFor="ownerRole" className={styles.ownerLabel}>
              Contact Owner Role
            </label>
            <input
              className={styles.ownerInput}
              id="ownerRole"
              {...register("ownerRole")}
              disabled={disableInput}
            />
          </div>
          <div>
            <label htmlFor="subOwnerName" className={styles.ownerLabel}>
              Sub Owner Name
            </label>
            <input
              className={styles.ownerInput}
              id="subOwnerName"
              {...register("subOwnerName")}
              disabled={disableInput}
            />
          </div>
          <div>
            <label htmlFor="subOwnerEmail" className={styles.ownerLabel}>
              Sub Owner Email
            </label>
            <input
              className={styles.ownerInput}
              id="subOwnerEmail"
              {...register("subOwnerEmail")}
              disabled={disableInput}
            />
          </div>
          <div>
            <label htmlFor="subOwnerPhone" className={styles.ownerLabel}>
              Sub Owner Phone
            </label>
            <input
              type="text"
              className={styles.ownerInput}
              id="subOwnerPhone"
              {...register("subOwnerPhone")}
              disabled={disableInput}
            />
          </div>
          <div>
            <label htmlFor="subOwnerRole" className={styles.ownerLabel}>
              Sub Owner Role
            </label>
            <input
              className={styles.ownerInput}
              id="subOwnerRole"
              {...register("subOwnerRole")}
              disabled={disableInput}
            />
          </div>
          <p className={styles.submitBtn}>
            <Button type="submit" disabled={isLoading}>
              {isLoading ? "Submitting" : "Submit"}
            </Button>
          </p>
        </form>
      </div>
      <div>
        <ContactOwnerTable isRefetch={isRefetch} />
      </div>
    </>
  );
};
