import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import ReportHistory from "../../components/ReportHistory";
import { ButtonLoader } from "../../components/ButtonLoader";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { runReport, searchUser, verifyAccount } from "../../service/api";
import { userReports } from "../../service/api";
import "../././../assets/Styling/customstyle.css";
import NumberFormatInput from "../../components/NumberFormatInput";
import {
  Col,
  Row,
  Card,
  Button,
  Breadcrumb,
  Form,
} from "@themesberg/react-bootstrap";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { fetchStatesData } from "../../service/api";
import { showNotification } from "../../service/showNotification";
import { reportInputSchema } from "../../yup";
import { TableSkeleton } from "../../skeleton/TableSkeleton";

const NewReports = () => {
  const [user, setUser] = useState("Start");
  const [clientId, setClientId] = useState(0);
  const [userId, setUserId] = useState(0);
  return (
    <Layout>
      <>
        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent",
              }}
            >
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>New Reports</Breadcrumb.Item>
            </Breadcrumb>
            <h4>Create New Report</h4>
          </div>
        </div>
        {user === "final" ? (
          <ReportForm
            setUser={setUser}
            clientId={clientId}
            userId={userId}
          />
        ) : user === "None" ? (
          <UserIdForm
            setUser={setUser}
            setUserId={setUserId}
            setClientId={setClientId}
          />
        ) : (
          <DropDown
            setUser={setUser}
            setUserId={setUserId}
            clientId={clientId}
            setClientId={setClientId}
          />
        )}
      </>
    </Layout>
  );
};
export const DropDown = ({ setUser, clientId, setClientId, setUserId }) => {
  const inlineStyle = { marginTop: "10px" };
  const heightStyle = { height: "110vh" };
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const handleSelect = (selectedOption) => {
    setUser(selectedOption.value);
    setId(selectedOption.value);
    setUserId(selectedOption.value);
    setClientId(selectedOption.id);
  };
  const fetchReports = useCallback(async () => {
    try{
      setLoading(true);
      const response = await userReports(id, currentPage);
      const data = response.data.data;
      setData(data.data);
      setTotalDocs(data.count);
      setIsSuccess(response.data.success);
      setLoading(false);
    }
    catch(err){
      console.log(err,"error")
      setLoading(false);
    }
  }, [currentPage, id])

  const getSearchUser = async (query) => {
    try{
      const response = await searchUser(query);
      const data = response.data.data;
      let searchedData = data.map((item) => ({
        value: item._id,
        label: item.firstName + " " + item.lastName,
        id: item.zohoCustomerId,
      }));
      searchedData.push({ value: "None", label: "None", id: "None" });
      return searchedData;
    }
    catch(err){
      console.log(err,"error")
    }
  };
  useEffect(() => {
    id.length > 0 && fetchReports();
  }, [id, currentPage, fetchReports]);
  return (
    <Card border="light">
      <Card.Body style={heightStyle}>
        <Form style={inlineStyle}>
          <Row>
            <Form.Group as={Col} sm="4" id="users">
              <Form.Label>Select User :</Form.Label>
              <Col>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  onChange={handleSelect}
                  loadOptions={getSearchUser}
                  placeholder="Please select user"
                />
              </Col>
              <Col sm="12" className="text-end mt-2 mb-4">
                <Button
                  disabled={isSuccess ? false : true}
                  onClick={() => setUser("final")}
                  type="Submit"
                >
                  Next
                </Button>
              </Col>
            </Form.Group>
          </Row>
        </Form>

        {loading ? (
          <TableSkeleton />
        ) : !clientId ? (
          ""
        ) : data?.length === 0 ? (
          <h1 className="mt-4 text-center w-full fs-4">No Data</h1>
        ) : (
          <ReportHistory
            data={data}
            clientId={clientId}
            currentPage={currentPage}
            loading={loading}
            setCurrentPage={setCurrentPage}
            totalDocs={totalDocs}
          />
        )}
      </Card.Body>
    </Card>
  );
};
export const UserIdForm = ({ setUser, setClientId, setUserId }) => {
  const inlineStyle = { marginTop: "10px" };
  const heightStyle = { height: "80vh" };
  const [zohoId, setZohoId] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      if (!error && zohoId) {
        const fetchedData = await fetchUsers();
        fetchedData && setUser("final");
      }
    }
    catch(err){
      console.log(err,"error")
    }
  };

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const response = await verifyAccount(zohoId);
      if (response?.data.success) {
        setUserId(response.data.data._id);
        setUser("final");
        setIsLoading(false);
      } else {
        setError(response.data.error);
        setIsLoading(false);
      }
    } catch (error) {
      showNotification("error", error.response.data.message);
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // Check if the input is numeric
    if (/^\d*$/.test(value)) {
      setClientId(value);
      setZohoId(value);
      setError("");
    } else {
      setError("Client ID must be numeric*");
    }
  };

  return (
    <Card border="light">
      <Card.Body style={heightStyle}>
        <Form style={inlineStyle} onSubmit={handleSubmit}>
          <Row>
            <Form.Group
              style={inlineStyle}
              as={Row}
              sm="6"
              id="stateTaxRatePercent"
            >
              <Form.Label className="d-flex align-items-center">
                Client ID:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  required
                  className="asterisk"
                  placeholder="Enter client Id *"
                  isInvalid={!!error}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {error}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group style={inlineStyle} as={Row} sm="12" id="name">
              <Col sm="5" className="text-end my-2">
                <Button
                  style={{ marginRight: "7px" }}
                  onClick={() => {
                    setUser("Start");
                  }}
                >
                  Back
                </Button>
                {isLoading ? (
                  <Button
                    className="button1"
                    type="Submit"
                    isLoading={isLoading}
                  >
                    {" "}
                    <ButtonLoader show={isLoading} />
                  </Button>
                ) : (
                  <>
                    <Button type="Submit" isLoading={isLoading}>
                      Next
                    </Button>
                  </>
                )}
              </Col>
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};
export const ReportForm = ({ setUser, clientId, userId }) => {
  const [states, setStates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const history = useHistory();

  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(reportInputSchema),
  });
  const [percentage, setPercentage] = useState("");
  const checkGrossIncome = watch("grossIncome");
  const checkEquality = watch("equalityContribution");

  useEffect(() => {
    if (checkGrossIncome) {
      clearErrors("equalityContribution");
      clearErrors("equalityContribution");
    }
  }, [checkGrossIncome, clearErrors]);
  useEffect(() => {
    if (
      (checkEquality &&
        checkGrossIncome &&
        checkEquality !== "$0" &&
        checkGrossIncome !== "$0") ||
      (checkEquality === "$0" && checkGrossIncome === "$0")
    ) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [checkGrossIncome, checkEquality]);

  const handlePress = (e) => {
    const keyCode = e.keyCode;
    if (keyCode === 8 || keyCode === 46) {
      const value = percentage;
      const newVal = value.slice(0, value.length - 1);
      setPercentage(newVal);
    }
  };

  const handlePercentageChange = (e) => {
    clearErrors("estFederalTaxRate");
    let value = e.target.value;
    const keyCode = e.keyCode || e.which;
    if (keyCode === 8) {
      value = value.replace(/%$/, "");
    } else {
      value = value.replace(/[^0-9]/g, "");
      let numericValue = parseInt(value, 10);
      if (!isNaN(numericValue)) {
        if (numericValue > 100) {
          numericValue = 100;
        }
        value = `${numericValue}%`;
      } else {
        value = "";
      }
    }
    setPercentage(value);
  };

  const getStatesData = async () => {
    try {
      const resp = await fetchStatesData();
      if (resp?.data.success) {
        setStates(resp.data.data);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getStatesData();
  }, []);
  const stateArray = states?.map((item) => {
    const { state, _id } = item;
    return {
      label: state,
      value: _id,
      ...item,
    };
  });
  const inlineStyle = { marginTop: "10px" };
  const heightStyle = { height: "80vh" };
  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      data.equalityContribution =
        data.equalityContribution === undefined
          ? 0
          : parseInt(data.equalityContribution.replace(/[$,]/g, ""));
      data.grossIncome =
        data.grossIncome === undefined
          ? 0
          : parseInt(data.grossIncome.replace(/[$,]/g, ""));
      data.estFederalTaxRate = parseInt(
        data.estFederalTaxRate.replace(/[^0-9]/g, "")
      );
      data.userId = userId;
      const response = await runReport(data);
      if (response.data.data) {
        showNotification("success", response.data.message);
      }

      history.push({
        pathname: Routes.ReportResult.path,
        state: response.data.data,
      });
      setIsLoading(false);
    } catch (error) {
      showNotification("error", error.response.data.message);
      setIsLoading(false);
    }
  };

  return (
    <Card border="light">
      <Card.Body style={heightStyle}>
        <Form style={inlineStyle} onSubmit={handleSubmit(onSubmit)}>

          <Row>
            <Form.Group style={inlineStyle} as={Row} sm="4" id="clientId">
              <Form.Label sm="1">Client Id:</Form.Label>
              <Col sm="3">
                <Form.Control
                  required
                  disabled
                  value={clientId}
                  placeholder="Enter ClientId"
                />
              </Col>
            </Form.Group>
            <Form.Group
              style={inlineStyle}
              as={Row}
              sm="4"
              id="equalityContribution"
            >
              <Form.Label sm="1">Equity Contribution:</Form.Label>
              <Col sm="3">
                <NumberFormatInput
                  control={control}
                  name="equalityContribution"
                  placeholder="Enter Equity Contribution *"
                />
              </Col>
            </Form.Group>
            {!getValues("equalityContribution") && (
              <>
                <Row sm="6">
                  <Col sm="3"></Col>
                  <Col
                    sm="3"
                    style={{
                      color: "red",
                      fontSize: "13px",
                    }}
                  >
                    {errors?.equalityContribution?.message}
                  </Col>
                </Row>
              </>
            )}
            {/* <Row> */}
            <Form.Group as={Row} sm="4" id="users">
              <Form.Label style={{ paddingTop: "10px" }}>
                Select State :
              </Form.Label>
              <Col style={{ paddingTop: "10px" }}>
                <Select
                  onChange={(e) => {
                    setValue("stateId", e.value);
                  }}
                  options={stateArray}
                  placeholder="Please select state"
                />
              </Col>
            </Form.Group>
            {!getValues("stateId") && (
              <>
                <Row sm="6">
                  <Col sm="3"></Col>
                  <Col
                    sm="3"
                    style={{
                      color: "red",
                      fontSize: "13px",
                    }}
                  >
                    {errors?.stateId?.message}
                  </Col>
                </Row>
              </>
            )}
            {/* </Row> */}
            <Form.Group style={inlineStyle} as={Row} sm="4" id="grossIncome">
              <Form.Label sm="2">Adjusted Gross Income:</Form.Label>
              <Col sm="3">
                <NumberFormatInput
                  control={control}
                  name="grossIncome"
                  placeholder="Enter Adjusted Gross Income"
                />
              </Col>
            </Form.Group>
            <Form.Group
              style={inlineStyle}
              as={Row}
              sm="4"
              id="estFederalTaxRate"
            >
              <Form.Label sm="2">Estimated Fedral Tax Rate:</Form.Label>
              <Col sm="3">
                <Form.Control
                  id="percent"
                  type="text"
                  name="estFederalTaxRate"
                  {...register("estFederalTaxRate")}
                  onKeyDown={handlePress}
                  value={percentage}
                  placeholder="Enter Fedral Tax Rate %"
                  onChange={handlePercentageChange}
                />
              </Col>
            </Form.Group>
            {!getValues("estFederalTaxRate") && (
              <>
                <Row sm="6">
                  <Col sm="3"></Col>
                  <Col
                    sm="3"
                    style={{
                      color: "red",
                      fontSize: "13px",
                    }}
                  >
                    {errors?.estFederalTaxRate?.message}
                  </Col>
                </Row>
              </>
            )}

            <Form.Group as={Row} sm="4" id="users" className="error">
              {showError && (
                <Col
                  sm="8"
                  style={{
                    color: "red",
                    marginTop: "1rem",
                  }}
                >
                  Enter either Equality contribution or Gross Income*
                </Col>
              )}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group style={inlineStyle} as={Row} sm="12" id="name">
              <Col sm="6" className="text-end">
                <Button
                  style={{ marginRight: "7px" }}
                  onClick={() => {
                    setUser("Start");
                  }}
                >
                  Back
                </Button>
                {isLoading ? (
                  <Button
                    className="button1"
                    type="Submit"
                    isLoading={isLoading}
                  >
                    {" "}
                    <ButtonLoader show={isLoading} />
                  </Button>
                ) : (
                  <>
                    <Button
                      type="Submit"
                      disabled={showError}
                      isLoading={isLoading}
                    >
                      Next
                    </Button>
                  </>
                )}
              </Col>
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};
export default NewReports;