import React, { useState, useEffect, useCallback } from "react";
import { Button,Pagination, Nav, Table } from "react-bootstrap";
import "../assets/Styling/customstyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import {
    Card,
    Breadcrumb,
} from "@themesberg/react-bootstrap";
import { TableSkeleton } from "../skeleton/TableSkeleton";
import { addToAllowList, downloadLicence, getAllowList, getEntityList, removeAllowList, updateStatus } from "../service/api";
import { EntityTablePopup } from "./EntityTablePopup";
import { EntityReplyPopup } from "./EntityReplyPopup";
import { useForm } from "react-hook-form";
import { zohoIdSchema } from "../yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { MdDownload } from "react-icons/md";
import { ButtonLoader } from "./ButtonLoader";
import Select from "react-select";


const columns = [
    { key: 'no', title: 'No.' },
    { key: 'zohoCustomerId', title: 'Zoho Customer ID' },
    { key: 'createdOn', title: 'Created On' },
    { key: 'email', title: 'Email' },
    { key: 'entityType', title: 'Entity Type' },
    { key: 'businessActivity', title: 'Business Activity' },
    { key: 'registeredState', title: 'Registered State' },
    { key: 'otherStates', title: 'Other States' },
    { key: 'desiredCoName1', title: 'Desired Co. Name1' },
    { key: 'desiredCoName2', title: 'Desired Co. Name2' },
    { key: 'desiredCoName3', title: 'Desired Co. Name3' },
    { key: 'mailingAddress', title: 'Co. Mailing Address' },
    { key: 'ssnEin', title: 'SSN/EIN' },
    { key: 'hireRegisteredAgent', title: 'Hire Registered Agent?' },
    { key: 'raPaymentAgree', title: 'Ra Payment Agree?' },
    { key: 'registeredAgentName', title: 'Registered Agent Name' },
    { key: 'membersLegalNames', title: 'Members Legal Names' },
    { key: 'download', title: 'Download' },
    { key: 'entityStatus', title: 'Entity Status' },
    { key: 'action', title: 'Action' }
  ];

export const EntityTable = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
    } = useForm({
        resolver: yupResolver(zohoIdSchema),
    });

    const [filteredData, setFilteredData] = useState([])
    const [allowUsers, setAllowUsers] = useState([])
    const [loading, setLoading] = useState(false);
    const [popup, setPopup] = useState(false)
    const [data, setData] = useState()
    const [reload, setReload] = useState(false)
    const [replyPopup, setReplyPopup] = useState(false)
    const [refetchAllowList, setRefetchAllowList] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [downloadLoader, setDownloadLoader] = useState(false)
    const [downloadIndex, setDownloadIndex] = useState(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);

  const itemsPerPage = 10;
  const showPaginationNumbers = totalPages > 10;

    const fetchEntity = useCallback(async () => {
        try{
            setLoading(true)
            const data = await getEntityList(currentPage, itemsPerPage);
            
            // reOrderData(data)
            setFilteredData(data.data.entity)
            const totalPages = Math.ceil(data.data.totalDocs / itemsPerPage);
            setTotalPages(totalPages);
            setLoading(false)
        }
        catch(err){
            console.log(err,"error")
            setLoading(false)
        }
    }, [currentPage, itemsPerPage])
    // const reOrderData = (data) => {
    //     const statusPriority = {
    //         open: 1,
    //         rejected: 2,
    //         completed: 3,
    //     };
    //     const filterData = [...data.data.entity];
    //     console.log('fil', filterData);
        

    //     filterData.sort((a, b) => {
    //         const statusComparison =
    //             statusPriority[a.entityStatus.toLowerCase()] -
    //             statusPriority[b.entityStatus.toLowerCase()];

    //         if (statusComparison !== 0) {
    //             return statusComparison;
    //         }

    //         if (a.entityStatus.toLowerCase() === "open") {
    //             return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
    //         } else if (
    //             a.entityStatus.toLowerCase() === "rejected" ||
    //             a.entityStatus.toLowerCase() === "completed"
    //         ) {
    //             return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    //         }

    //         return 0;
    //     });
    //     setFilteredData(filterData)
    // }

    const openPopup = (item) => {
        setData(item)
        setPopup(true);
    }

    const openReplyPopup = (item, e) => {
        e.stopPropagation()
        setData(item)
        setReplyPopup(true)
    }

    const changeAllowList = async (item) => {
        try{
            const result = await removeAllowList(item.zohoCustomerId);
            console.log('get', result);
            if (result.data.success)
                setRefetchAllowList(prev => !prev)
        }
        catch(err){
            console.log(err,"error")
        }
    }

    const handleZohoId = async (value) => {
        setIsLoading(true)
        try {
            const result = await addToAllowList(value)
            if (result.data.success) {
                reset()
                // setSuccess(true)
                setRefetchAllowList(prev => !prev)
            }
        }
        catch (err) {
            console.log(err);
            setError('zohoId', { type: 'Error', message: 'Failed to add user' });
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchAllowList = async () => {
        try {
            const result = await getAllowList()
            setAllowUsers(result.data.data)
        }
        catch (err) {
            console.log(err);
        }
    }

    const downloadAttachment = async (e, fileId, zohoId, index) => {
        e.stopPropagation()
        setDownloadLoader(true)
        setDownloadIndex(index)
        try {
            if (!fileId) {
                console.log("No ID found")
                return;
            }
            const response = await downloadLicence({ fileId, zohoId })
            if (!response.data) {
                throw new Error('File not downloaded');
            }
            const blob = response.data;
            const contentDisposition = response.headers.get('Content-Disposition');
            let filename = 'Driver_Licence';
            if (contentDisposition && contentDisposition.includes('filename=')) {
                const filenameMatch = contentDisposition.match(/filename="([^"]*)"/);
                if (filenameMatch && filenameMatch[1]) {
                    filename = filenameMatch[1];
                }
            }
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }
        catch (error) {
            console.log(error)
        }
        finally {
            setDownloadLoader(false)
        }
    }
    const modelOptions = [
        { value: "Open", label: "Open" },
        { value: "Completed", label: "Completed" },
        { value: "Rejected", label: "Rejected" },
    ];

    const handleStatusUpdate = async (status, zohoId) => {
        try {
            await updateStatus({ status, zohoId })
            setReload(prev => !prev)
            // reOrderData(filteredData)
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchEntity()
    }, [reload, fetchEntity, currentPage])

    useEffect(() => {
        fetchAllowList()
    }, [refetchAllowList])

    return (
        <>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mx-3">
                <div className="d-block mb-4 mb-xl-0">
                    <Breadcrumb
                        className="d-none d-md-inline-block "
                        listProps={{
                            className: "breadcrumb-dark breadcrumb-transparent ",
                        }}
                    >
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Entity Table</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Entity Table</h4>
                </div>
                <div className="search-container">
                    <form onSubmit={handleSubmit(handleZohoId)} style={{ display: 'flex', flexDirection: 'col' }}>
                        <span>
                            <div>
                                <input
                                    style={{ height: '1.9rem' }}
                                    type="text"
                                    placeholder="Enter Zoho ID"
                                    {...register('zohoId')}
                                />
                            </div>
                            {errors.zohoId && <p style={{ fontSize: '.8rem', color: 'red' }}>{errors.zohoId.message}</p>}
                            {/* {success && <p style={{ fontSize: '.8rem', color: 'green' }}>User added to allow list</p>} */}
                        </span>
                        <Button style={{ height: '2rem', marginLeft:"1rem" }} size="sm" variant="dark" type="submit">
                            {isLoading ? <ButtonLoader show={true} /> : 'ADD'}
                        </Button>
                    </form>
                </div>
            </div>

            {loading ? (
                <TableSkeleton />
            ) : (
                <div className="scrollable-table-container2 mx-3">
                <Card
                  border="light"
                  className="table-wrapper table-responsive shadow-sm"
                >
                  <Card.Body className="pt-0">
                    <Table hover className="user-table align-items-center">
                      <thead>
                        <tr>
                          {columns.map((header) => <th key={header.key}>{header.title}</th>)}
                        </tr>
                      </thead>
                      <tbody>
                                    {filteredData?.length <= 0 ? (
                                        <tr>
                                            <td className="text-center bg-grey" colSpan={100}>
                                                No Records Found.
                                            </td>
                                        </tr>
                                    ) : (
                                        filteredData.map((item, index) => {
                                            return (
                                                <tr key={item._id} onClick={() => openPopup(item)}>
                                                    <td>{index + 1}.</td>
                                                    <td>{item.zohoCustomerId}</td>
                                                    <td>{item.createdAt.substring(0,10)}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.isCorporation ? "Corporation" : "Limited Liability Company"}</td>
                                                    <td>{item.businessType}</td>
                                                    <td>{item.companyBuiltState}</td>
                                                    <td>{item.qualityRegisterNewCompany}</td>
                                                    <td>{item.potentialCompanyName1 ?? "-"}</td>
                                                    <td>{item.potentialCompanyName2 ?? "-"}</td>
                                                    <td>{item.potentialCompanyName3 ?? "-"}</td>
                                                    <td>{item.companyMailingAddress}</td>
                                                    <td>{item.entityStatus === 'Open' ?
                                                        item.socialSecurityNumber
                                                        :
                                                        'REDACTED ' + item.updatedAt.substring(5, 7) + '.' + item.updatedAt.substring(8, 10) + '.' + item.updatedAt.substring(0, 4)
                                                    }
                                                    </td>
                                                    <td>{item.registerAgentRequired ? "YES" : "NO"}</td>
                                                    <td>{item.isContractAgentFee ? "YES" : "-"}</td>
                                                    <td>{item.registerAgentFullName ?? "-"}</td>
                                                    <td>{item.membersLegalName ?? '-'}</td>
                                                    <td onClick={(e) => downloadAttachment(e, item.driverLicence, item.zohoCustomerId, index)} className="text-center" >
                                                        {downloadLoader && index === downloadIndex ?
                                                            <ButtonLoader show={true} />
                                                            :
                                                            <MdDownload className="fs-4" />
                                                        }
                                                    </td>
                                                    <td
                                                        onClick={e => e.stopPropagation()}
                                                    >
                                                        <Select
                                                            className="model-select select-dropdown w-100"
                                                            value={item.entityStatus}
                                                            onChange={e => handleStatusUpdate(e.value, item.zohoCustomerId)}
                                                            options={modelOptions}
                                                            placeholder={item.entityStatus ?? '-'}
                                                            menuPortalTarget={document.body}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Button
                                                            variant="success"
                                                            disabled={item.entityStatus !== 'Rejected'}
                                                            size="sm"
                                                            onClick={(e) => openReplyPopup(item, e)}
                                                        >
                                                            Reply
                                                        </Button>{' '}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                    </Table>
                    <Card.Footer className="px-3 border-0 d-lg-flex  justify-content-between">
                    <Nav>
                        <Pagination className="mb-2 mb-lg-0">
                            <Pagination.Prev
                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                            >
                            Previous
                            </Pagination.Prev>
                            {showPaginationNumbers && (
                            <>
                                {currentPage > 4 && (
                                <>
                                    <Pagination.Item onClick={() => setCurrentPage(1)}>
                                    1
                                    </Pagination.Item>
                                    <Pagination.Ellipsis disabled />
                                </>
                                )}
                                {[...Array(totalPages)].map((_, page) => {
                                if (
                                    (page === 0 && currentPage < 5) ||
                                    (page === totalPages - 1 && currentPage > totalPages - 5) ||
                                    (page > currentPage - 3 && page < currentPage + 2)
                                ) {
                                    return (
                                    <Pagination.Item
                                        key={page + 1}
                                        active={page + 1 === currentPage}
                                        onClick={() => setCurrentPage(page + 1)}
                                    >
                                        {page + 1}
                                    </Pagination.Item>
                                    );
                                }
                                return null;
                                })}
                                {currentPage < totalPages - 3 && (
                                <>
                                    <Pagination.Ellipsis disabled />
                                    <Pagination.Item onClick={() => setCurrentPage(totalPages)}>
                                    {totalPages}
                                    </Pagination.Item>
                                </>
                                )}
                            </>
                            )}
                            <Pagination.Next
                            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                            disabled={currentPage === totalPages}
                            >
                            Next
                            </Pagination.Next>
                        </Pagination>
                    </Nav>
                    </Card.Footer>
                  </Card.Body>
                </Card>
              </div>


            )}


            {/* allow list */}

            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mx-3 mt-5">
                <div className="d-block mb-4 mb-xl-0">
                    <h4>Allow Table</h4>
                </div>
            </div>

            {loading ? (
                <TableSkeleton />
            ) : (
                <div className="scrollable-table-container2 mx-3">
                    <Card
                        border="light"
                        className="table-wrapper table-responsive shadow-sm"
                    >
                        <Card.Body className="pt-0">
                            <Table hover className="user-table align-items-center">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Zoho Customer ID</th>
                                        <th>Email</th>
                                        <th>Name</th>
                                        <th>Created On</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allowUsers.length <= 0 ? (
                                        <tr>
                                            <td className="text-center bg-grey" colSpan={100}>
                                                No Records Found.
                                            </td>
                                        </tr>
                                    ) : (
                                        allowUsers.map((item, index) => (
                                            <tr key={item._id}>
                                                <td>{index + 1}</td>
                                                <td>{item.zohoCustomerId}</td>
                                                <td>{item.userId?.email || '-'}</td>
                                                <td>{item.userId?.firstName + " " + (item.userId?.lastName)}</td>
                                                <td>{item.createdAt.substring(0, 10)}</td>
                                                <td
                                                    style={{
                                                        color: 'red',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => changeAllowList(item)}
                                                >
                                                    Remove
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
            )}

            {popup && <EntityTablePopup setPopup={setPopup} data={data} downloadLoader={downloadLoader} downloadAttachment={downloadAttachment} setReload={setReload} />}
            {replyPopup && <EntityReplyPopup setReplyPopup={setReplyPopup} data={data} setReload={setReload} />}

        </>
    );
}
export default EntityTable