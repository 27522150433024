import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { deleteProformaUser, editProformaUser } from "../../service/api";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import styles from "./index.module.css";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import styles2 from "../../pages/proformaUser/addProforma.module.css";
import { IoIosClose } from "react-icons/io";
import { showNotification } from "../../service/showNotification";

const ProformaUserTable = ({ data, getUsers, contactOptions }) => {
  const { register, reset, handleSubmit, control } = useForm();
  const [userData, setUserData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const runOptions = [
    {
      label: "Internal",
      value: "Internal",
    },
    {
      label: "External",
      value: "External",
    },
  ];

  const [selectedOptions, setSelectedOptions] = useState(runOptions[0]);
  const setUser = async (item) => {
    setIsEdit(true);
    reset({
      userName: item.name,
      pin: item.pin,
    });
    if (item?.designation === "External") {
      setSelectedOptions({ value: "External", label: "External" });
    } else {
      setSelectedOptions({ value: "Internal", label: "Internal" });
    }
  };

  const deleteUser = async (id) => {
    try {
      const response = await deleteProformaUser(id);
      const data = response.data.data;
      console.log(data);
      getUsers();
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (values) => {
    console.log(values);
    try {
      const payload = {
        name: values.userName,
        pin: parseInt(values.pin),
        designation: selectedOptions.label,
      };
      if (selectedOptions.label === "External") {
        payload["contactGroup"] = values.contactGroup;
      }
      await editProformaUser(payload);
      setIsEdit(false);
      getUsers();
      showNotification("success", "User updated successfully");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (data.length > 0) {
      setUserData(data);
    }
  }, [data]);

  return (
    <>
      <div className="scrollable-table-container2">
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
          style={{ maxHeight: "80vh", overflowY: "auto" }}
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Pin</th>
                  <th>Designation</th>
                  <th>Contact Group</th>
                  <th>Low Scenerio %</th>
                  <th>Low-Mid Scenerio %</th>
                  <th>High-Mid Scenerio %</th>
                  <th>High Scenerio %</th>
                  <th>Developer Dev Fee %</th>
                  <th>ITC %</th>
                  <th>FolderId</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userData?.length <= 0 ? (
                  <tr>
                    <td className="text-center bg-grey" colSpan={100}>
                      No Records Found.
                    </td>
                  </tr>
                ) : (
                  userData.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td>{item.name}</td>
                        <td>{item.pin}</td>
                        <td>{item.designation}</td>
                        <td>
                          {item?.contactGroup?.contactGroupName
                            ? item?.contactGroup.contactGroupName
                            : "-"}
                        </td>
                        <td>
                          {item?.contactGroup?.lowScenerio
                            ? `${item?.contactGroup.lowScenerio}%`
                            : "-"}
                        </td>
                        <td>
                          {item?.contactGroup?.lowMidScenerio
                            ? `${item?.contactGroup.lowMidScenerio}%`
                            : "-"}
                        </td>
                        <td>
                          {item?.contactGroup?.highMidScenerio
                            ? `${item?.contactGroup.highMidScenerio}%`
                            : "-"}
                        </td>
                        <td>
                          {item?.contactGroup?.highScenerio
                            ? `${item?.contactGroup.highScenerio}%`
                            : "-"}
                        </td>
                        <td>{item?.contactGroup?.developerDevFee ?? "-"}</td>
                        <td>{item?.contactGroup?.itcPercent ?? "-"}</td>
                        <td>{item?.contactGroup?.folderId ?? "-"}</td>
                        <td>
                          <span>
                            <FaEdit
                              className={`${styles.editButton} ${
                                item.designation === "External" &&
                                styles.disableEdit
                              } `}
                              onClick={() => setUser(item)}
                            />
                          </span>
                          <span>
                            <MdDelete
                              className={styles.deleteButton}
                              onClick={() => deleteUser(item._id)}
                            />
                          </span>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
      {isEdit && (
        <div className={styles.overlay}>
          <div className={styles.overlayContent}>
            <p className={styles.closeBtn} onClick={() => setIsEdit(false)}>
              <IoIosClose />
            </p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={styles.outerFormContainer}
            >
              <p className={styles2.heading}>Update User</p>
              <div className={styles2.formContainer}>
                <div className={styles2.inputContainer}>
                  <label className={styles2.labelStyle}>User Name</label>
                  <input
                    disabled
                    {...register("userName")}
                    className={styles2.inputBox}
                    placeholder="Enter user name"
                    type="text"
                  />
                </div>
                <div className={styles2.inputContainer}>
                  <label className={styles2.labelStyle}>Pin</label>
                  <input
                    {...register("pin")}
                    className={styles2.inputBox}
                    placeholder="Enter Pin"
                    type="number"
                  />
                </div>
                <div className={styles2.inputContainer}>
                  <label className={styles2.labelStyle}>Designation</label>
                  <Select
                    options={runOptions}
                    value={{
                      value: selectedOptions.value,
                      label: selectedOptions.label,
                    }}
                    onChange={(selected) => setSelectedOptions(selected)}
                  />
                </div>
                {selectedOptions.label === "External" && (
                  <div className={styles.inputContainer}>
                    <label className={styles.labelStyle}>Contact Group</label>
                    <Controller
                      name="contactGroup"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={contactOptions}
                          value={contactOptions.find(
                            (option) => option.value === field.value
                          )}
                          placeholder="Select an Contact Group"
                          onChange={(selectedOption) =>
                            field.onChange(selectedOption.value)
                          }
                        />
                      )}
                    />
                  </div>
                )}
              </div>
              <input className={styles2.submitButton} type="submit" />
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ProformaUserTable;
