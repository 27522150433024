import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import styles from "./addProforma.module.css";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import {
  createContactGroup,
  getContactGroup,
  getProformaUser,
  getProformaUsers,
  registerProformaUser,
  updateProformaValues,
} from "../../service/api";
import { showNotification } from "../../service/showNotification";
import ProformaUserTable from "../../components/ProformaUserTable/index";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactGroup, proformaUser } from "../../yup";
import ContactGroupTable from "../../components/ContactGroupTable";

const AddProformaUser = () => {
  const runOptions = [
    {
      label: "Internal",
      value: "Internal",
    },
    {
      label: "External",
      value: "External",
    },
  ];
  const scenerioOptions = [
    {
      label: "1%",
      value: "1",
    },
    {
      label: "2%",
      value: "2",
    },
    {
      label: "3%",
      value: "3",
    },
    {
      label: "4%",
      value: "4",
    },
    {
      label: "5%",
      value: "5",
    },
  ];
  const [selectedOptions, setSelectedOptions] = useState(runOptions[0]);
  const [selectedLowScenerioOptions, setSelectedLowScenerioOptions] = useState(
    scenerioOptions[0]
  );
  const [selectedLowMidScenerioOptions, setSelectedLowMidScenerioOptions] =
    useState(scenerioOptions[0]);
  const [selectedHighMidScenerioOptions, setSelectedHighMidScenerioOptions] =
    useState(scenerioOptions[0]);
  const [selectedHighScenerioOptions, setSelectedHighScenerioOptions] =
    useState(scenerioOptions[0]);
  const [selectedContactGroup, setSelectedContactGroup] = useState({});
  const [userData, setUserData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [contactOptions, setContactOptions] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(proformaUser),
    context: { isExternal: selectedOptions.label === "External" },
  });
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
  } = useForm();

  const {
    register: contactRegister,
    handleSubmit: contactSubmit,
    formState: { errors:contactGroupError },
    reset: contactReset,
  } = useForm({
    resolver: yupResolver(contactGroup),
  });

  const getUsers = async () => {
    try {
      const response = await getProformaUsers();
      const data = response.data.data;
      setUserData(data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchContactGroup = async () => {
    try {
      const response = await getContactGroup();
      const data = response.data.data;
      setContactData(data);
      if (data) {
        const transformedData = data.map((item) => {
          return {
            label: item.contactGroupName,
            value: item._id,
          };
        });
        setContactOptions(transformedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (values) => {
    try {
      const data = {
        name: values.userName,
        pin: values.pin,
        designation: selectedOptions.value,
      };
      if (selectedOptions.label === "External") {
        data["contactGroup"] = values.contactGroup;
      }
      const response = await registerProformaUser(data);
      if (response.data.success) {
        showNotification("success", "User created successfully");
        getUsers();
        reset({
          userName: "",
          pin: null,
        });
      }
    } catch (error) {
      showNotification(
        "error",
        error.response.data.message || "Something went wrong"
      );
    }
  };
  const onDefaultValueSubmit = async (values) => {
    try {
      const data = {
        inceptionDevFee: values.inceptionDevFeeDefault,
        developerDevFee: values.developerDevFeeDefault,
        itcPercent: values.itcPercentDefault,
        folderId: values.folderIdDefault,
      };
      const response = await updateProformaValues(data);
      if (response.data.success) {
        showNotification("success", "Default values updated successfully");
        fetchProformaValues();
      }
    } catch (error) {
      showNotification(
        "error",
        error.response.data.message || "Something went wrong"
      );
    }
  };
  const onContactGroupSubmit = async (values) => {
    try {
      const emailArray = values.email.split(/\s+/);
   
      const data = {
        contactGroupName: values.contactGroupName,
        developerDevFee: values.developerDevFee,
        itcPercent: values.itcPercent,
        folderId: values.folderId,
        lowScenerio: selectedLowScenerioOptions.value,
        lowMidScenerio: selectedLowMidScenerioOptions.value,
        highMidScenerio: selectedHighMidScenerioOptions.value,
        highScenerio: selectedHighScenerioOptions.value,
        emails:emailArray,
      };
      const response = await createContactGroup(data);
      if (response.data.success) {
        showNotification("success", response.data.message);
      }
      contactReset({
        contactGroupName: "",
        folderId: "",
        email:""
      });
    } catch (error) {
      showNotification(
        "error",
        error.response.data.message || "Something went wrong"
      );
    }
  };

  const fetchProformaValues = useCallback(async () => {
    try{
      const response = await getProformaUser();
      const data = response.data.data[0];
      contactReset({
        inceptionDevFee: data?.inceptionDevFee,
        developerDevFee: data?.developerDevFee,
        itcPercent: data?.itcPercent,
      });
      reset2({
        inceptionDevFeeDefault: data?.inceptionDevFee,
        developerDevFeeDefault: data?.developerDevFee,
        itcPercentDefault: data?.itcPercent,
        folderIdDefault: data?.folderId,
      });
    }
    catch(err){
      console.log(err,"error")
    }
  }, [contactReset, reset2]);

  useEffect(() => {
    fetchProformaValues();
  }, [fetchProformaValues]);

  useEffect(() => {
    getUsers();
    fetchContactGroup();
  }, []);

  return (
    <>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mx-3">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb
            className="d-none d-md-inline-block "
            listProps={{
              className: "breadcrumb-dark breadcrumb-transparent ",
            }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Proforma Users</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Proforma users</h4>
        </div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.outerFormContainer}
      >
        <p className={styles.heading}>Create Pro Forma User</p>
        <div className={styles.formContainer}>
          <div className={styles.inputContainer}>
            <label className={styles.labelStyle}>User Name</label>
            <input
              {...register("userName")}
              className={styles.inputBox}
              placeholder="Enter user name"
              type="text"
            />
            {errors?.userName && (
              <span className={styles.errorText}>
                {errors?.userName?.message}
              </span>
            )}
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelStyle}>Pin</label>
            <input
              {...register("pin")}
              className={styles.inputBox}
              placeholder="Enter Pin"
              type="number"
            />
            {errors?.pin && (
              <span className={styles.errorText}>{errors?.pin?.message}</span>
            )}
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelStyle}>Designation</label>
            <Select
              options={runOptions}
              value={{
                value: selectedOptions.value,
                label: selectedOptions.label,
              }}
              onChange={(selected) => setSelectedOptions(selected)}
            />
          </div>
          {selectedOptions.value === "External" && (
            <div className={styles.inputContainer}>
              <label className={styles.labelStyle}>Contact Group</label>
              <Controller
                name="contactGroup"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={contactOptions}
                    value={contactOptions.find(
                      (option) => option.value === field.value
                    )}
                    placeholder="Select an Contact Group"
                    onChange={(selectedOption) =>
                      field.onChange(selectedOption.value)
                    }
                  />
                )}
              />
              {errors?.contactGroup && (
                <span className={styles.errorText}>
                  {errors?.contactGroup?.message}
                </span>
              )}
            </div>
          )}
        </div>
        <input className={styles.submitButton} type="submit" />
      </form>
      <form
        onSubmit={handleSubmit2(onDefaultValueSubmit)}
        className={styles.outerFormContainer}
      >
        <p className={styles.heading}>Baseline Pro Forma Values</p>
        <div className={styles.formContainer}>
          <div className={styles.inputContainer}>
            <label className={styles.labelStyle}>Inception Dev Fee %</label>
            <input
              {...register2("inceptionDevFeeDefault")}
              className={styles.inputBox}
              placeholder="Enter Inception Dev Fee %"
              type="text"
            />
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelStyle}>Developer Dev Fee %</label>
            <input
              {...register2("developerDevFeeDefault")}
              className={styles.inputBox}
              placeholder="Enter Developer Dev Fee %"
              type="text"
            />
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelStyle}> ITC %</label>
            <input
              {...register2("itcPercentDefault")}
              className={styles.inputBox}
              placeholder="Enter ITC %"
              type="text"
            />
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelStyle}>Destination Folder ID</label>
            <input
              {...register2("folderIdDefault")}
              className={styles.inputBox}
              placeholder="Enter folder ID"
              type="text"
            />
          </div>
        </div>
        <input className={styles.submitButton} type="submit" />
      </form>
      <form
        onSubmit={contactSubmit(onContactGroupSubmit)}
        className={styles.outerFormContainer}
      >
        <p className={styles.heading}>Contact Group</p>
        <div className={styles.formContainer}>
          <div className={styles.inputContainer}>
            <label className={styles.labelStyle}>Contact Group Name</label>
            <input
              {...contactRegister("contactGroupName")}
              className={styles.inputBox}
              placeholder="Enter Contact Group name"
              type="text"
            />
            {errors?.contactGroupName && (
              <span className={styles.errorText}>
                {errors?.contactGroupName?.message}
              </span>
            )}
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelStyle}>Baseline Dev Fee %</label>
            <input
              {...contactRegister("developerDevFee")}
              className={styles.inputBox}
              placeholder="Enter Baseline Dev Fee %"
              type="text"
            />
            {errors?.developerDevFee && (
              <span className={styles.errorText}>
                {errors?.developerDevFee?.message}
              </span>
            )}
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelStyle}> Baseline ITC %</label>
            <input
              {...contactRegister("itcPercent")}
              className={styles.inputBox}
              placeholder="Enter Baseline ITC %"
              type="text"
            />
            {errors?.itcPercent && (
              <span className={styles.errorText}>
                {errors?.itcPercent?.message}
              </span>
            )}
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelStyle}>Destination Folder ID</label>
            <input
              {...contactRegister("folderId")}
              className={styles.inputBox}
              placeholder="Enter folder ID"
              type="text"
            />
            {errors?.folderId && (
              <span className={styles.errorText}>
                {errors?.folderId?.message}
              </span>
            )}
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelStyle}>Low Scenerio %</label>
            <Select
              options={scenerioOptions}
              value={{
                value: selectedLowScenerioOptions.value,
                label: selectedLowScenerioOptions.label,
              }}
              onChange={(selected) => setSelectedLowScenerioOptions(selected)}
            />
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelStyle}>Low-Mid Scenerio %</label>
            <Select
              options={scenerioOptions}
              value={{
                value: selectedLowMidScenerioOptions.value,
                label: selectedLowMidScenerioOptions.label,
              }}
              onChange={(selected) =>
                setSelectedLowMidScenerioOptions(selected)
              }
            />
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelStyle}>High-Mid Scenerio %</label>
            <Select
              options={scenerioOptions}
              value={{
                value: selectedHighMidScenerioOptions.value,
                label: selectedHighMidScenerioOptions.label,
              }}
              onChange={(selected) =>
                setSelectedHighMidScenerioOptions(selected)
              }
            />
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelStyle}>High Scenerio %</label>
            <Select
              options={scenerioOptions}
              value={{
                value: selectedHighScenerioOptions.value,
                label: selectedHighScenerioOptions.label,
              }}
              onChange={(selected) => setSelectedHighScenerioOptions(selected)}
            />
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelStyle}>Email</label>
            <input
              {...contactRegister("email")}
              className={styles.inputBox}
              placeholder="Enter Email"
              type="text"
            />
              {contactGroupError.email && (
                <p className={styles.errorText}>{contactGroupError.email.message}</p>
              )}
        
          </div>
        </div>
        <input className={styles.submitButton} type="submit" />
      </form>
      <div className={styles.outerFormContainer}>
        <p className={styles.heading}>Users List</p>
        <ProformaUserTable
          data={userData}
          getUsers={getUsers}
          contactOptions={contactOptions}
        />
      </div>
      <div className={styles.outerFormContainer}>
        <p className={styles.heading}>Contact Group List</p>
        <ContactGroupTable
          data={contactData}
          fetchContactGroup={fetchContactGroup}
          getUsers={getUsers}
        />
      </div>
    </>
  );
};

export default AddProformaUser;
