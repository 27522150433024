import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import {
  Form,
  Breadcrumb,
  Button,
  Modal,
} from "@themesberg/react-bootstrap";

import { SubAdminTable } from "../components/Tables";
import {
  createSubadminApi,
  deleteSubadminApi,
  getSubadminApi,
  updateSubadminApi,
} from "../service/api";
import Layout from "../components/Layout";
import { showNotification } from "../service/showNotification";
import { TableSkeleton } from "../skeleton/TableSkeleton";

const SubAdmin = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [user, setUser] = useState({
    email: "",
    name: "",
    errors: { email: "", name: "" },
  });

  const [loading, setloading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const handleAddModal = () => setShowAddModal(false);

  const validateName = (name) => {
    if(/[^a-zA-Z\s]+/.test(name)) return "Name must be characters."
    if (name.trim().length < 3) return "Name must be at least 3 characters long.";
    return "";
  };
  const validateEmail = (email) => {
    if (!/^\S+@\S+\.\S+$/.test(email)) return "Email address is invalid.";
    return "";
  };
  

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModal = async () => {
    try {
      const { errors, ...payload} = user
      const resp = await updateSubadminApi(id, payload);
      if (resp) {
        getData();
        setShowUpdateModal(false);
      }
    } catch (err) {
      setShowUpdateModal(false);
    }
    setShowUpdateModal(false);
  };
 
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteModal = async () => {
    try {
      const resp = await deleteSubadminApi(id);
      if (resp) {
        showNotification("success", resp?.data?.message);
        getData();
        setShowDeleteModal(false);
      }
    } catch (err) {
      setShowDeleteModal(false);
    }
  };

  const [currPage, setcurrPage] = useState(1);

  const handleUpdateUser = () => {
    setShowUpdateModal(true);
  };

    const handleChange = (event, name) => {
    const value = event.target.value;
    let errors = { ...user.errors };
    if (name === "name") {
      errors.name = validateName(value);
    } else if (name === "email") {
      errors.email = validateEmail(value);
    }
    setUser({
      ...user,
      [name]: value,
      errors,
    });
  };

  const getData = useCallback( async () => {
    try {
      setloading(true);
      const resp = await getSubadminApi(currPage);

      if (resp?.data.data.data.length >= 1) {
        setloading(false);
        setData(resp.data.data.data);
        setTotalDocs(resp.data.data.count);
      } else {
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      console.timeLog("err", error);
    }
  },[currPage])

  const handleCreateUser = async () => {
    const { name, email, errors } = user;
    errors.name = validateName(name);
    errors.email = validateEmail(email);
    if (errors.name || errors.email) {
      setUser({ ...user, errors });
      return; 
    }
    try {
      const resp = await createSubadminApi({ name, email });
      if (resp?.data?.data) {
        getData();
        handleAddModal();
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getData();
  }, [currPage,getData]);

  return (
    <Layout>
      <>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent",
              }}
            >
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>SubAdmin List</Breadcrumb.Item>
            </Breadcrumb>
            <h4>SubAdmin List</h4>
          </div>
          <Button onClick={() => setShowAddModal(true)}>Add SubAdmin</Button>
        </div>
       
        {loading ? (
          <TableSkeleton/>
        ) : (
          <>
            <SubAdminTable
              handleUpdateUser={handleUpdateUser}
              setShowDeleteModal={setShowDeleteModal}
              data={data}
              setcurrPage={setcurrPage}
              currPage={currPage}
              totalDocs={totalDocs}
              setId={setId}
              setShowUpdateModal={setShowUpdateModal}
            />

            <React.Fragment>
              <Modal
                as={Modal.Dialog}
                centered
                show={showAddModal}
                onHide={handleAddModal}
              >
                <Modal.Header>
                  <Modal.Title className="h6">Add New User</Modal.Title>
                  <Button
                    variant="close"
                    aria-label="Close"
                    onClick={handleAddModal}
                  />
                </Modal.Header>
                <Modal.Body >
                  <Form.Group  controlId="name">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={user.name}
                      placeholder="Enter user's full name"
                      name="name"
                      onChange={(e)=>handleChange(e, "name")}
                      isInvalid={!!user.errors.name}
                    />
                     <Form.Control.Feedback type="invalid">
                    {user.errors.name}
                    </Form.Control.Feedback>
              
                  </Form.Group>
                  <Form.Group controlId="email" >
                    {/* id="email" */}
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter user's email"
                      name="email"
                      value={user.email}
                      onChange={(e)=>handleChange(e, "email")}
                      isInvalid={!!user.errors.email}
                    />
                      <Form.Control.Feedback type="invalid">
                     {user.errors.email}
                   </Form.Control.Feedback>

                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={() => handleAddModal(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    className="text-white ms-auto"
                    onClick={handleCreateUser}
                  >
                    Send invitation
                  </Button>
                </Modal.Footer>
              </Modal>
            </React.Fragment>

            <React.Fragment>
              <Modal
                as={Modal.Dialog}
                centered
                show={showUpdateModal}
                // onClick={() => setShowUpdateModal(false)}
              >
                <Modal.Header>
                  <Modal.Title className="h6">Update User's Detail</Modal.Title>
                  <Button
                    variant="close"
                    aria-label="Close"
                    onClick={() => setShowUpdateModal(false)}
                  />
                </Modal.Header>
                <Modal.Body>
                  <Form.Group id="name">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter user's full name"
                      onChange={(e)=>handleChange(e,"name")}
                      isInvalid={!!user.nameError} 
                    />
                  </Form.Group>
                  <Form.Group id="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter user's email"
                      onChange={(e)=>handleChange(e,"email")}
                      isInvalid={!!user.emailError}
                    />
                       <Form.Control.Feedback type="invalid">
                    
                        {user.nameError}
                    
                    </Form.Control.Feedback>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="link"
                    onClick={() => setShowUpdateModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    className="text-white ms-auto"
                    onClick={handleUpdateModal}
                  >
                    Update
                  </Button>
                </Modal.Footer>
              </Modal>
            </React.Fragment>

            <React.Fragment>
              <Modal
                as={Modal.Dialog}
                centered
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
              >
                <Modal.Header>
                  <Modal.Title className="h6">Delete User</Modal.Title>
                  <Button
                    variant="close"
                    aria-label="Close"
                    onClick={() => setShowDeleteModal(false)}
                  />
                </Modal.Header>
                <Modal.Body>
                  <Form.Label>
                    Are you sure ? You wants to delete this user.
                  </Form.Label>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="link"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    className="text-white ms-auto"
                    onClick={handleDeleteModal}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </React.Fragment>
          </>
        )}
      </>
      {/* )} */}
    </Layout>
  );
};

export default SubAdmin;