import React, { useState, useEffect, useRef } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../assets/Styling/customstyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Form, Row, Breadcrumb } from "@themesberg/react-bootstrap";
import { showNotification } from "../service/showNotification";
import { getNdaApi, updateNdaApi } from "../service/api";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useForm } from "react-hook-form";


function NDAForm() {
  const [showSidebar, setShowSidebar] = useState(true);
  const [description, setDescription] = useState("");
  const [ndaTitle, setNdaTitle] = useState("");
  const { register, handleSubmit } = useForm()
  const inlineStyle = { marginTop: "10px" };
  const editorRef = useRef();

  const updateNDAForm = async () => {
    try {
      const body = {
        title: ndaTitle,
        description: description,
      }
      const response = await updateNdaApi(body);
      if (response) {
        showNotification("success", response?.data?.message);
      } else {
        showNotification("error", 'Failed to update');
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const getData = async () => {
    try {
      const response = await getNdaApi();
      if (response) {
        showNotification("success", response?.data?.message);
        setDescription(response?.data?.data?.description);
        setNdaTitle(response?.data?.data?.title);
      } else {
        showNotification("error", 'Failed to update');
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  }

  const handleClearClick = () => {
    const editor = editorRef.current.editor;
    editor.setData('');
    setNdaTitle("")
  };

  useEffect(() => {
    getData();
  }, [])

  const onTitleChange = (e) => {
    setNdaTitle(e.target.value)
  }



  const handleResize = () => {
    if (window.innerWidth <= 763) {
      setShowSidebar(true);
    } else {
      setShowSidebar(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const onSubmit = async (values) => {
    setNdaTitle(values.ndaTitle)
    try{
      await updateNDAForm();
    }
    catch(err){
      console.log(err, "error")
    }
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setDescription(data)
  };

  return (
    <>
      {showSidebar && <Sidebar />}
      <div className={showSidebar ? "component" : "component-full-width"}>
        <div className="mx-3">
          <Navbar />
        </div>

        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mx-3">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb
              className="d-none d-md-inline-block "
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent ",
              }}
            >
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>NDA Form </Breadcrumb.Item>
            </Breadcrumb>
            <h4>NDA Form </h4>
          </div>
        </div>

        <div className=" mx-3 ">
          <div className="row">
            <div className="customization-form col-md-6 ">
              <Form onSubmit={handleSubmit(onSubmit)} className="w-full">
                <Row className="flex flex-col w-full">
                  <Form.Group
                    style={inlineStyle}
                    className="pl-2"
                    as={Col}
                    sm="12"
                    id="ndaTitle"
                  >
                    <Form.Label>NDA Title :</Form.Label>
                    <Form.Control
                      {...register("ndaTitle")}
                      value={ndaTitle}
                      onChange={onTitleChange}
                      placeholder="Enter NDA Title"
                    />
                  </Form.Group>
                  <Form.Label className="mt-2">NDA Description :</Form.Label>
                  <div className="editorStyle">
                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onChange={handleEditorChange}
                      ref={editorRef}
                    />
                  </div>
                </Row>
                <div className="flex justify-end items-end  w-full text-right mt-2">
                  <Button variant="primary" type="submit">Update</Button>
                  <Button variant="primary" className="mx-2" onClick={handleClearClick} >Clear</Button>
                </div>
              </Form>
            </div>
          </div>
        </div>

        <div className="footer">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default NDAForm;
