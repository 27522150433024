import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./index.module.css";
import styles2 from "../../pages/proformaUser/addProforma.module.css";
import { Button } from "react-bootstrap";
import { showNotification } from "../../service/showNotification";
import { updateContactOwner } from "../../service/api";

export const ContactOwnerPopup = ({
  rowData,
  setIsEditClicked,
  fetchContactOwner,
}) => {
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (values) => {
    try {
      console.log(values);
      await updateContactOwner(values);
      showNotification("success", "Values updated successfully");
      fetchContactOwner();
    } catch (error) {
      showNotification("error", "Something went wrong");
    } finally {
      setIsEditClicked(false);
    }
  };

  useEffect(() => {
    if (rowData && Object.keys(rowData)) {
      reset({
        userId: rowData?.userId,
        ownerName: rowData?.ownerName,
        ownerEmail: rowData?.ownerEmail,
        ownerPhone: rowData?.ownerPhone,
        ownerRole: rowData?.ownerRole,
        subOwnerName: rowData?.subOwnerName,
        subOwnerEmail: rowData?.subOwnerEmail,
        subOwnerPhone: rowData?.subOwnerPhone,
        subOwnerRole: rowData?.subOwnerRole,
      });
    }
  }, [rowData, reset]);

  return (
    <>
      <div
        className="overlay"
        // onClick={() => setIsEditClicked(false)}
      >
        <div>
          <div className="overlayContent" onClick={(e) => e.stopPropagation()}>
            <span className="closeBtn" onClick={() => setIsEditClicked(false)}>
              &times;
            </span>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={styles.outerFormContainer}
            >
              <p className={styles2.heading}>Update Assets Details</p>
              <div className={styles.rowBreak}>
                <div className={styles.inputContainer}>
                  <label className={styles2.labelStyle}>User ID</label>
                  <input
                        {...register("userId")}
                        disabled
                        className={styles2.inputBox}
                        placeholder="Enter User Id"
                        type="text"
                      />
                </div>
                <div className={styles.inputContainer}>
                  <label className={styles2.labelStyle}>Contact Owner Name</label>
                  <input
                        {...register("ownerName")}
                        className={styles2.inputBox}
                        placeholder="Enter Contact Owner Name"
                        type="text"
                      />
                </div>
                <div className={styles.inputContainer}>
                    <label className={styles2.labelStyle}>Contact Owner Email</label>
                    <input
                          {...register("ownerEmail")}
                          className={styles2.inputBox}
                          placeholder="Enter Contact Owner Email"
                          type="text"
                        />
                </div>
              </div>
              <div className={styles.rowBreak}>
                <div className={styles.inputContainer}>
                <label className={styles2.labelStyle}>Contact Owner Phone</label>
                <input
                      {...register("ownerPhone")}
                      className={styles2.inputBox}
                      placeholder="Enter Contact Owner Phone"
                      type="text"
                    />
                </div>
                <div className={styles.inputContainer}>
                <label className={styles2.labelStyle}>Contact Owner Role</label>
                <input
                      {...register("ownerRole")}
                      className={styles2.inputBox}
                      placeholder="Enter Contact Owner Role"
                      type="text"
                    />
                </div>
                <div className={styles.inputContainer}>
                <label className={styles2.labelStyle}>SubOwner Name</label>
                <input
                      {...register("subOwnerName")}
                      className={styles2.inputBox}
                      placeholder="Enter SubOwner Name"
                      type="text"
                    />
                </div>
              </div>
              <div className={styles.rowBreak}>
                <div className={styles.inputContainer}>
                <label className={styles2.labelStyle}>SubOwner Email</label>
                <input
                      {...register("subOwnerEmail")}
                      className={styles2.inputBox}
                      placeholder="Enter SubOwner Email"
                      type="text"
                    />
                </div>
                <div className={styles.inputContainer}>
                <label className={styles2.labelStyle}>SubOwner Phone</label>
                <input
                      {...register("subOwnerPhone")}
                      className={styles2.inputBox}
                      placeholder="Enter SubOwner Phone"
                      type="text"
                    />
                </div>
                <div className={styles.inputContainer}>
                <label className={styles2.labelStyle}>SubOwner Role</label>
                <input
                      {...register("subOwnerRole")}
                      className={styles2.inputBox}
                      placeholder="Enter SubOwner Role"
                      type="text"
                    />
                </div>
                </div>
              <div className={styles.inputContainer2}>
                <Button type="submit" style={{ marginLeft: "auto" }}>
                  Edit Contacts
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
