import React, { useCallback, useEffect, useState } from 'react'
import { showNotification } from "../../service/showNotification";
import { TableSkeleton } from '../../skeleton/TableSkeleton';
import { Card, Table } from 'react-bootstrap';
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { deleteContactOwner, getAllContactOwner } from '../../service/api';
import { ContactOwnerPopup } from './ContactOwnerPopup';

const ContactOwnerTable = ({isRefetch}) => {
    const headerFields = [
        "User Id",
        "Contact Owner Name",
        "Contact Owner Email",
        "Contact Owner Phone",
        "Contact Owner Role",
        "Sub Owner Name",
        "Sub Owner Email",
        "Sub Owner Phone",
        "Sub Owner Role",
        "Action"
    ]
    const [isEditClicked, setIsEditClicked] = useState(false)
    const [loading, setLoading] = useState(false)
    const [rowData, setRowData] = useState([])
    const [contactOwner, setContactOwner] = useState([])

    const deleteStyles = {
        marginRight: "10px",
        color: "red",
        fontSize: "1.2rem",
      };
    const EditStyles = {
      color: "green",
      fontSize: "1.2rem",
    };

    const fetchContactOwner = useCallback(async () => {
        try {
            setLoading(true)
          const response = await getAllContactOwner();
          setContactOwner(response.data)
          setLoading(false)
        } catch (error) {
          console.log(error);
          setLoading(false)
        }
    }, [isEditClicked, isRefetch]);

    const handleDelete = async (id) => {
        try {
          await deleteContactOwner(id);
          showNotification("success", "Row deleted successfully");
          fetchContactOwner();
        } catch (error) {
          showNotification("Something went wrong");
        }
      };

    const handleEdit = async (item) => {
      setRowData(item);
      setIsEditClicked(true);
    };

    useEffect(() => {
        fetchContactOwner();
    }, [fetchContactOwner]);

    return (
        <>
          {loading ? (
            <TableSkeleton />
          ) : (
            <div className="scrollable-table-container2 mx-3">
              <Card
                border="light"
                className="table-wrapper table-responsive shadow-sm"
              >
                <Card.Body className="pt-0">
                  <Table hover className="user-table align-items-center">
                    <thead>
                      <tr>
                        {headerFields.map((header, index) => {
                          return <th>{header}</th>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {contactOwner?.map((item) => {
                        return (
                          <tr key={item._id}>
                            <th>{item?.userId || "-"}</th>
                            <th>{item?.ownerName || "-"}</th>
                            <th>{item?.ownerEmail || "-"}</th>
                            <th>{item?.ownerPhone || "-"}</th>
                            <th>{item?.ownerRole || "-"}</th>
                            <th>{item?.subOwnerName || "-"}</th>
                            <th>{item?.subOwnerEmail || "-"}</th>
                            <th>{item?.subOwnerPhone || "-"}</th>
                            <th>{item?.subOwnerRole || "-"}</th>
                            <th style={{ width: "3rem" }}>
                              <MdDelete
                                onClick={() => handleDelete(item?._id)}
                                style={deleteStyles}
                              />
                              <FaEdit
                                onClick={() => handleEdit(item)}
                                style={EditStyles}
                              />
                            </th>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </div>
          )}
          {isEditClicked && (
            <ContactOwnerPopup
              rowData={rowData}
              setIsEditClicked={setIsEditClicked}
              fetchContactOwner={fetchContactOwner}
            />
          )}
        </>
      );
}

export default ContactOwnerTable