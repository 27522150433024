import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "@themesberg/react-bootstrap";
import { TransactionsTable } from "../components/Tables";
import { getReportData } from "../service/api";
import Layout from "../components/Layout";
import Select from "react-select";
import "react-loading-skeleton/dist/skeleton.css";
import { TableSkeleton } from "../skeleton/TableSkeleton";
import { Col, Row } from "@themesberg/react-bootstrap";
import { searchUser } from "../service/api";
import AsyncSelect from "react-select/async";

export default () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [totalDocs, setTotalDocs] = useState(0);
  const [currPage, setcurrPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    setSearch(event.value);
  };

  const getSearchUser = async (query) => {
    try{
      const response = await searchUser(query);
      const data = response.data.data;
      let searchedData = data.map((item) => ({
        value: item.zohoCustomerId,
        label: item.firstName + " " + item.lastName,
        id: item.zohoCustomerId,
      }));
      searchedData.push({ value: "None", label: "None", id: "None" });
      return searchedData;
    }
    catch(err){
      console.log(err,"error")
    }
  };

  const modelOptions = [
    { value: "all", label: "All" },
    { value: "estimated", label: "Estimated" },
    { value: "actual", label: "Actual" },
  ];
  const [selectedModel, setSelectedModel] = useState(modelOptions[0]);
  const handleModelChange = (selectedOption) => {
    setSelectedModel(selectedOption);
  };

  const fetchReports = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await getReportData(currPage, selectedModel.value, search);
      const responseData = resp.data.data;
      if (responseData.data.length >= 1) {
        setData(responseData.data);
        setTotalDocs(responseData.count);
      } else {
        setError("No data available.");
      }
    } catch (error) {
      setError("Error fetching data.");
    } finally {
      setLoading(false);
    }
  }, [currPage, search, selectedModel.value])

  useEffect(() => {
    fetchReports();
  }, [currPage, selectedModel, search, fetchReports]);
  return (
    <Layout>
      <>
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center py-4">
          <div className="mb-4 mb-md-0" style={{ flex: '3' }}>
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent",
              }}
            >
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Reports</Breadcrumb.Item>
            </Breadcrumb>
            <h4>Reports</h4>
          </div>
          <div className="search-container mb-4 mb-md-0" style={{ flex: "2" }}>
            <Row className="w-100">
              <Col xs={12} md={6} className="mb-3 mb-md-0">
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  onChange={handleSearch}
                  loadOptions={getSearchUser}
                  placeholder="Please select user"
                />
              </Col>
              <Col xs={12} md={6} className="p-0">
                <Select
                  className="model-select select-dropdown w-100"
                  value={selectedModel}
                  onChange={handleModelChange}
                  options={modelOptions}
                  placeholder="Select a Model"
                />
              </Col>
            </Row>
          </div>
        </div>

        {loading ? (
          <TableSkeleton />
        ) : error ? (
          <div className="text-danger">{error}</div>
        ) : (
          <TransactionsTable
            data={data}
            totalDocs={totalDocs}
            setCurrentPage={setcurrPage}
            currPage={currPage}
            setSearch={setSearch}
            search={search}
          />
        )}
      </>
    </Layout >
  );
};
